import React, { useEffect, useState } from "react";
import { Link, withPrefix } from "gatsby";
import Select from "react-select";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import dayjs from "dayjs";
import { ThemeProvider } from "@material-ui/styles";
import { occasioMaterialTheme } from "../../components/mui/muiUtils";
import PlainPage from "../../templates/plain-page";
import {
  firebaseApp,
  db,
  recaptchaVerifier,
  emailAuthCredential,
  phoneAuthCredential,
  storageRef,
} from "../../components/firebase/firestore";
import {
  firebaseEmailSettings,
  getInvitedUsersCount,
  getVerifierName,
} from "../../components/auth/Auth";
import {
  getCountriesList,
  getLanguagesList,
  getAccountTypeList,
  getSectorList,
  MakeRequired,
  isValidDate,
  isFullAge,
  formatDate,
} from "../../utils/utils";
import Modal from "../../components/Modal";
import ImageTools from "../../utils/imageTools";
import placeholder128 from "../../../static/img/placeholder-128x128.png";

const Content = location => ({ currentUser }) => {
  const [emailInputDisabled, setEmailInputDisabled] = useState(false);
  const [emaildBtnDisabled, setEmailBtnDisabled] = useState(true);
  const [emaildVerified, setEmailVerified] = useState(
    currentUser && currentUser.emailVerified,
  );
  const [phoneBtnDisabled, setPhoneBtnDisabled] = useState(true);
  const [phoneVerified, setPhoneVerified] = useState(
    currentUser && currentUser.phoneNumber,
  );
  const [passwordBtnDisabled, setPasswordBtnDisabled] = useState(true);

  const [cvFile, setCvFile] = useState({ name: "" });
  // only for Download file button
  const [cvDownloadUrl, setCvDownloadUrl] = useState();
  const [avatarFile, setAvatarFile] = useState();
  const countiesList = getCountriesList();
  const [selectedCountry, setSelectedCountry] = useState();
  const sectorList = getSectorList();
  const [selectedSector, setSelectedSector] = useState();
  const languagesList = getLanguagesList();
  const [selectedLangs, setSelectedLangs] = useState();
  const typeList = getAccountTypeList();
  const [selectedType, setSelectedType] = useState();
  const [, setInviterName] = useState();
  const [, setVerifierName] = useState();
  const [, setRefUsers] = useState({
    totalCount: 0,
    notVerifiedCount: 0,
  });
  const [, setSignupDate] = useState();
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalCb, setModalCb] = useState({
    submit: () => {},
    deny: () => {
      setModalOpen(false);
    },
  });

  const [inviteTemplatesOpen, setInviteTemplatesOpen] = useState(false);
  const [inviteTemplatesCb] = useState({
    submit: () => {},
    deny: () => {
      setInviteTemplatesOpen(false);
    },
  });

  useEffect(() => {
    window.recaptchaVerifier = recaptchaVerifier("recaptcha-container");
  }, []);

  useEffect(() => {
    if (currentUser) {
      const userRef = db.collection("users").doc(currentUser.uid);

      // email form
      document.querySelector("#email-field").value = currentUser.email;
      // only enable email change if signed up using email
      setEmailInputDisabled(
        currentUser.providerData.reduce(
          (accumulator, currentValue) =>
            !(!accumulator || currentValue.providerId === "password"),
          true,
        ),
      );

      // phone form
      document.querySelector("#phone-field").value = currentUser.phoneNumber;

      // profile form
      userRef.get().then(doc => {
        if (doc.exists) {
          // fill up html elements
          [
            ...document.querySelectorAll(
              "#profile-form input[type=text], #profile-form input[type=url]",
            ),
          ].forEach(element => {
            const dbVal = doc.data()[element.name];
            if (dbVal !== undefined) {
              element.value = dbVal;
            }
          });

          // fill up react components
          if (Array.isArray(doc.data().languages)) {
            setSelectedLangs(
              doc.data().languages.map(e => ({ value: e, label: e })),
            );
          } else if (doc.data().languages) {
            setSelectedLangs({
              value: doc.data().languages,
              label: doc.data().languages,
            });
          }
          if (doc.data().country) {
            setSelectedCountry({
              value: doc.data().country,
              label: doc.data().country,
            });
          }
          if (doc.data().sector) {
            setSelectedSector({
              value: doc.data().sector,
              label: doc.data().sector,
            });
          }
          if (doc.data().type) {
            setSelectedType({
              value: doc.data().type,
              label: doc.data().type,
            });
          }
          if (doc.data().birth) {
            setDateOfBirth(dayjs(doc.data().birth, "DD-MM-YYYY"));
          }

          // fill up cv file name and download url
          const cvName = doc.data().cv;
          setCvFile({ name: cvName });
          if (cvName) {
            storageRef
              .child(`cv/${currentUser.uid}/${cvName}`)
              .getDownloadURL()
              .then(url => {
                setCvDownloadUrl(url);
              })
              .catch(error => {
                alert(`Error loading CV file. ${error.message}`);
              });
          }
          // set avatar
          storageRef
            .child(`avatar/${currentUser.uid}`)
            .getDownloadURL()
            .then(url => {
              document.getElementById("avatar-preview").src = url;
            })
            .catch(() => {
              // don't show error if avatar not found
            });
        }
      });

      // Inviter name
      const refRef = db.collection("ref").doc(currentUser.uid);
      refRef.get().then(doc => {
        if (doc.exists) {
          const inviterUid = doc.data().inviter_uid;
          if (inviterUid) {
            const invRef = db.collection("users").doc(inviterUid);
            invRef.get().then(invDoc => {
              if (invDoc.exists) {
                const data = invDoc.data();
                setInviterName(
                  data.first_name && data.surname
                    ? `${data.first_name} ${data.surname}`
                    : "anonymous user",
                );
              }
            });
          }
        }
      });

      // Verification status
      getVerifierName(currentUser.uid).then(name => setVerifierName(name));

      // ref users count
      getInvitedUsersCount(currentUser.uid).then(data => {
        setRefUsers(data);
      });
    }

    // Signup date
    // don't get from users/uid/signUpDate to save on queries
    setSignupDate(formatDate(new Date(currentUser.metadata.creationTime)));

    // it's better to listen to currentUser.uid, but it can be undefined
  }, [currentUser]);

  // reauthenticate user before email/phone/password change (google policy)
  const reAuthUser = (parentFunc, event) => {
    setModalCb({
      ...modalCb,
      submit: async e => {
        const password = e.target.elements["current-password-field"].value;
        const credential = emailAuthCredential(currentUser.email, password);
        await currentUser
          .reauthenticateWithCredential(credential)
          .then(() => {
            setModalOpen(false);
            parentFunc(event);
          })
          .catch(error => {
            if (error.code === "auth/wrong-password") {
              alert("Wrong password. Try again");
            } else {
              alert(`Error reauthenticating. ${error.message}`);
            }
          });
      },
    });
    setModalOpen(true);
  };

  const handleEmailSubmission = event => {
    event.preventDefault();

    const button = document.getElementById("save-email-btn");
    button.classList.add("is-loading");

    const newEmail = document.querySelector("#email-field").value;

    if (currentUser.email === newEmail) {
      alert("Plase enter a new email address.");
      button.classList.remove("is-loading");
      return;
    }

    currentUser
      .updateEmail(newEmail)
      .then(() => {
        currentUser.sendEmailVerification(firebaseEmailSettings);
        alert(
          "Email changed! Please confirm it by following a link in the email we sent you.",
        );
      })
      .catch(error => {
        if (error.code === "auth/requires-recent-login") {
          reAuthUser(handleEmailSubmission);
        } else {
          alert(`Error saving data. ${error.message}`);
        }
      })
      .finally(() => button.classList.remove("is-loading"));
  };

  const handlePhoneSubmission = event => {
    event.preventDefault();

    const button = document.getElementById("save-phone-btn");
    button.classList.add("is-loading");

    const newPhone = document.querySelector("#phone-field").value;

    if (currentUser.phoneNumber === newPhone) {
      alert("Plase enter a new phone number.");
      button.classList.remove("is-loading");
      return;
    }

    const phoneParsed = parsePhoneNumberFromString(newPhone);
    if (!(phoneParsed && phoneParsed.isValid())) {
      alert(
        "Phone number is invalid. Please make sure you follow the required format.",
      );
      button.classList.remove("is-loading");
      return;
    }

    const appVerifier = window.recaptchaVerifier;

    firebaseApp
      .auth()
      .signInWithPhoneNumber(newPhone, appVerifier)
      .then(async confirmationResult => {
        window.confirmationResult = confirmationResult;

        // otherwise captcha will rerender and cause prompt to disappear
        // TODO find better solution
        document.querySelector("#recaptcha-container").innerHTML = "";

        const code = prompt(
          "Please enter code from SMS sent to your phone.",
          "",
        );
        if (code !== null) {
          if (currentUser.phoneNumber) {
            await currentUser.unlink("phone");
          }
          const credential = phoneAuthCredential(
            confirmationResult.verificationId,
            code,
          );

          await currentUser.linkWithCredential(credential);
          alert("Success! Phone number verified.");
          setPhoneBtnDisabled(true);
          setPhoneVerified(true);
        }
      })
      .catch(error => {
        window.recaptchaVerifier.render().then(widgetId => {
          grecaptcha.reset(widgetId);
        });
        if (error.code === "auth/requires-recent-login") {
          reAuthUser(handlePhoneSubmission, event);
        } else {
          alert(`Error verifying phone number. ${error.message}`);
        }
      })
      .finally(() => {
        window.recaptchaVerifier = recaptchaVerifier("recaptcha-container");
        button.classList.remove("is-loading");
      });
  };

  const handlePasswordSubmission = event => {
    event.preventDefault();

    const button = document.getElementById("save-password-btn");
    button.classList.add("is-loading");

    const passwordField = document.querySelector("#new-password-field");

    currentUser
      .updatePassword(passwordField.value)
      .then(() => {
        alert("Password changed!");
        passwordField.value = "";
        setPasswordBtnDisabled(true);
      })
      .catch(error => {
        if (error.code === "auth/requires-recent-login") {
          reAuthUser(handlePasswordSubmission, event);
        } else {
          alert(`Error saving data. ${error.message}`);
        }
      })
      .finally(() => button.classList.remove("is-loading"));
  };

  const handleCVSelect = event => {
    const fileTemp = event.target.files[0];
    if (fileTemp && fileTemp.size) {
      if (fileTemp.size > 5000000) {
        alert("Please select file smaller than 5 Mb");
        return;
      }

      if (fileTemp.type !== "application/pdf") {
        alert("Wrong file type. Only PDF files are supported.");
        return;
      }

      setCvFile(fileTemp);
    }
  };

  const handleAvatarSelect = event => {
    const fileTemp = event.target.files[0];
    if (fileTemp && fileTemp.size) {
      if (!["image/png", "image/jpeg"].includes(fileTemp.type)) {
        alert("Wrong file type. Only JPG and PNG files are supported.");
        return;
      }

      ImageTools.resize(
        fileTemp,
        {
          width: 256, // maximum width
          height: 256, // maximum height
        },
        (blob, didItResize) => {
          // didItResize will be true if it managed to resize it, otherwise false (and will return the original file as 'blob')
          document.getElementById(
            "avatar-preview",
          ).src = window.URL.createObjectURL(blob);

          setAvatarFile(blob);
        },
      );
    }
  };

  const handleProfileSubmission = event => {
    event.preventDefault();

    // check for valid date (regexp doesn't cover all cases)
    const date = document.querySelector("input[name=birth]").value;
    if (date && !isValidDate(date)) {
      alert("Wrong date. Please enter existing birth date.");
      return;
    }

    if (date && isFullAge(date)) {
      alert(
        "You must be at least 18 years old to use this site. Please consult our terms and conditions.",
      );
      return;
    }

    const button = document.getElementById("save-profile-btn");
    button.classList.add("is-loading");

    const data = [
      ...document.querySelectorAll(
        `#${event.target.id} input[type=text], #${event.target.id} input[type=url], #${event.target.id} input[type=hidden]`,
      ),
    ]
      .filter(e => e.name)
      .reduce((accumulator, currentValue) => {
        if (accumulator.hasOwnProperty(currentValue.name)) {
          if (Array.isArray(accumulator[currentValue.name])) {
            accumulator[currentValue.name].push(currentValue.value);
          } else {
            accumulator[currentValue.name] = [
              accumulator[currentValue.name],
              currentValue.value,
            ];
          }
        } else {
          accumulator[currentValue.name] = currentValue.value;
        }
        return accumulator;
      }, {});

    // add CV filename for reference to Firebase Storage, since we can't get it from there
    if (cvFile.name) {
      data.cv = cvFile.name;
    }

    const userRef = db.collection("users").doc(currentUser.uid);
    userRef
      .update(data)
      .then(async () => {
        // CV upload
        if (cvFile.size) {
          const cvRef = storageRef.child(
            `cv/${currentUser.uid}/${cvFile.name}`,
          );
          await cvRef.put(cvFile);
          await cvRef.getDownloadURL().then(url => {
            setCvDownloadUrl(url);
          });

          // this way old user files are not deleted
        }
        // Avatar upload
        if (avatarFile) {
          const avatarRef = storageRef.child(`avatar/${currentUser.uid}`);
          await avatarRef.put(avatarFile);
        }
        alert("Success! Data saved.");
      })
      .catch(error => {
        alert(`Error saving data. ${error.message}`);
      })
      .finally(() => button.classList.remove("is-loading"));
  };

  return (
    <div className="column is-10 is-offset-1">
      {/*  <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
        Profile settings
      </h2> */}
      <p className="is-size-6 m-b-md">
        Ensure your data is accurate and in line with our{" "}
        <Link to="/terms">terms</Link>. Accounts that violate our terms will be
        suspended without notice.
      </p>

      <div className="columns is-desktop">
        <div className="column panel is-12-desktop ">
          <p className="panel-heading">Profile Settings</p>

          <div className="panel-block">
            <form
              id="profile-form"
              action=""
              className="control"
              onSubmit={handleProfileSubmission}
            >
              <div className="columns is-desktop">
                <div className="column is-4-desktop ">
                  <div className="field">
                    <label htmlFor="" className="label">
                      Profile picture
                    </label>

                    <span className="label">
                      <figure className="image is-cropped-64x64 is-cursor-pointer">
                        <input
                          id="avatar-input"
                          className="file-input is-cursor-pointer"
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          name="avatar"
                          onChange={handleAvatarSelect}
                          style={{
                            fontSize: "0",
                            height: "1px",
                          }}
                        />
                        <img
                          id="avatar-preview"
                          className="is-rounded"
                          src={placeholder128}
                          alt="avatar"
                          onClick={() => {
                            document.getElementById("avatar-input").click();
                          }}
                        />
                        <span
                          className="avatar-overflow has-text-centered"
                          onClick={() => {
                            document.getElementById("avatar-input").click();
                          }}
                        >
                          <span className="icon has-text-white">
                            <i className="icon fas fa-camera" />
                          </span>
                        </span>
                      </figure>
                    </span>
                    {/* <div className="control">
                      <div className="file is-success">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            name="avatar"
                            onChange={handleAvatarSelect}
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="fas fa-upload" />
                            </span>
                            <span className="file-label">
                              Choose a file (JPG and PNG only)
                            </span>
                          </span>

                          //  <span className="file-name">{avatarFile.name}</span> 
                        </label>
                      </div>

                      //  <MakeRequired required={!cvFile.name} /> 
                    </div> */}
                  </div>
                  <div className="field">
                    <label htmlFor="" className="label">
                      First name<span className="has-text-danger"> *</span>
                    </label>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="Jane"
                        className="input"
                        name="first_name"
                        autoComplete="given-name"
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="" className="label">
                      Surname<span className="has-text-danger"> *</span>
                    </label>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="Smith"
                        className="input"
                        name="surname"
                        autoComplete="family-name"
                        required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="" className="label">
                      Date of Birth
                    </label>
                    <div className="control">
                      <ThemeProvider theme={occasioMaterialTheme}>
                        <MuiPickersUtilsProvider utils={DayjsUtils}>
                          <DatePicker
                            name="birth"
                            value={dateOfBirth}
                            onChange={setDateOfBirth}
                            format="DD-MM-YYYY"
                            openTo="year"
                            disableFuture
                            views={["year", "month", "date"]}
                            maxDate={dayjs().subtract(18, "year")}
                            maxDateMessage="You must be at least 18 years old"
                            fullWidth
                            inputVariant="outlined"
                            autoOk
                            placeholder="Choose date of birth"
                            okLabel=""
                            cancelLabel=""
                            // required
                            inputProps={{ readOnly: false }}
                          />
                        </MuiPickersUtilsProvider>
                      </ThemeProvider>
                      {/* <input
                    type="text"
                    inputMode="numeric"
                    placeholder="31-12-2019"
                    className="input"
                    name="birth"
                    pattern="(3[01]|[12][0-9]|0?[1-9])-(1[012]|0?[1-9])-((?:19|20)\d{2})"
                  /> */}
                    </div>
                    {/* <p className="help">
                  <i>format: 31-12-2019</i>
                </p> */}
                  </div>
                </div>
                <div className="column is-4-desktop ">
                  <div className="field">
                    <label htmlFor="" className="label">
                      CV
                    </label>
                    <div className="control">
                      <div className="file has-name is-fullwidth is-success">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            accept=".pdf"
                            name="cv"
                            onChange={handleCVSelect}
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="fas fa-upload" />
                            </span>
                            <span className="file-label">
                              Choose a file (PDF only)
                            </span>
                          </span>
                          <span className="file-name">{cvFile.name}</span>
                        </label>
                      </div>
                      {/* <MakeRequired required={!cvFile.name} /> */}
                    </div>

                    {cvDownloadUrl ? (
                      <a target="_blank" href={cvDownloadUrl}>
                        Download file
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="field">
                    <label htmlFor="" className="label">
                      Languages
                    </label>
                    <div className="control">
                      <Select
                        isClearable={false}
                        isSearchable
                        isMulti
                        name="languages"
                        options={
                          selectedLangs &&
                          Object.keys(selectedLangs).length >= 5
                            ? selectedLangs
                            : languagesList
                        }
                        onChange={values => {
                          setSelectedLangs(values);
                        }}
                        value={selectedLangs}
                        noOptionsMessage={() =>
                          "You can't choose more than 5 languages"
                        }
                      />
                      {/* <MakeRequired required={!selectedLangs} /> */}
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="" className="label">
                      Sector
                    </label>
                    <div className="control">
                      <Select
                        isClearable={false}
                        isSearchable
                        name="sector"
                        options={sectorList}
                        onChange={values => {
                          setSelectedSector(values);
                        }}
                        value={selectedSector}
                      />
                      {/* <MakeRequired required={!selectedSector} /> */}
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="" className="label">
                      Institution/Organisations name
                    </label>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="Corporation inc."
                        className="input"
                        name="organization"
                        // required
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-4-desktop ">
                  <div className="field">
                    <label htmlFor="" className="label">
                      Institution/Organisations website
                    </label>
                    <div className="control">
                      <input
                        type="url"
                        placeholder="example.com"
                        className="input"
                        name="website"
                        // required
                      />
                      <p className="help">
                        <i>format: http://example.com</i>
                      </p>
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="" className="label">
                      Profile headline
                    </label>
                    <div className="control">
                      <input
                        type="text"
                        placeholder="e.g. Minister of Finance or Final Year Law Student"
                        className="input"
                        name="headline"
                        // required
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="" className="label">
                      Country<span className="has-text-danger"> *</span>
                    </label>
                    <div className="control">
                      <Select
                        isClearable={false}
                        isSearchable
                        name="country"
                        options={countiesList}
                        onChange={values => {
                          setSelectedCountry(values);
                        }}
                        value={selectedCountry}
                      />
                      <MakeRequired required={!selectedCountry} />
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="" className="label">
                      Account type<span className="has-text-danger"> *</span>
                    </label>
                    <div className="control">
                      <Select
                        name="type"
                        options={typeList}
                        onChange={values => {
                          setSelectedType(values);
                        }}
                        value={selectedType}
                      />
                      <MakeRequired required={!selectedType} />
                    </div>
                  </div>
                  <div className="field is-grouped is-grouped-right">
                    <button id="save-profile-btn" className="button is-success">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="columns is-desktop">
        <div className="column">
          <div className="panel">
            <p className="panel-heading">Security</p>
            <div className="panel-block">
              <div className="control">
                <div className="columns is-desktop">
                  <div className="column is-4">
                    <form
                      id="email-form"
                      action=""
                      className=""
                      onSubmit={handleEmailSubmission}
                    >
                      <label htmlFor="" className="label">
                        Email
                      </label>

                      <div className="field">
                        <div className="field has-addons ">
                          <div className="control has-icons-left is-expanded">
                            <input
                              id="email-field"
                              type="email"
                              placeholder="e.g. jonhdoe@gmail.com"
                              className="input"
                              name="email"
                              autoComplete="email"
                              required
                              disabled={emailInputDisabled}
                              onChange={e => {
                                setEmailBtnDisabled(
                                  !e.target.value ||
                                    e.target.value === currentUser.email,
                                );
                                setEmailVerified(
                                  e.target.value === currentUser.email &&
                                    currentUser.emailVerified,
                                );
                              }}
                            />
                            <span className="icon is-small is-left">
                              <i className="fa fa-envelope" />
                            </span>
                          </div>
                          <div className="control">
                            <span
                              className={`button is-static-${
                                emaildVerified ? "success" : "danger"
                              }`}
                            >
                              {emaildVerified ? "Verified" : "Not verified"}
                            </span>
                          </div>
                        </div>
                        <div className="field is-grouped is-grouped-right">
                          <button
                            id="save-email-btn"
                            className="button is-success"
                            disabled={emaildBtnDisabled}
                          >
                            Verify
                          </button>
                        </div>
                        {/* <p className="help is-success">Address confirmed</p> */}
                      </div>
                    </form>
                  </div>

                  <div className="column is-4">
                    <form
                      id="phone-form"
                      action=""
                      className=""
                      onSubmit={handlePhoneSubmission}
                    >
                      <label htmlFor="" className="label">
                        Phone number
                      </label>
                      <div className="field is-marginless">
                        <div className="field has-addons ">
                          <div className="control has-icons-left is-expanded">
                            <input
                              id="phone-field"
                              type="tel"
                              placeholder="+18001234567"
                              className="input"
                              name="phone"
                              autoComplete="tel"
                              required
                              onChange={e => {
                                setPhoneBtnDisabled(
                                  !e.target.value ||
                                    e.target.value === currentUser.phoneNumber,
                                );
                                setPhoneVerified(
                                  e.target.value &&
                                    e.target.value === currentUser.phoneNumber,
                                );
                              }}
                              onFocus={() => {
                                document.getElementById(
                                  "phone-help",
                                ).style.display = "block";
                              }}
                              onBlur={() => {
                                setTimeout(() => {
                                  document.getElementById(
                                    "phone-help",
                                  ).style.display = "none";
                                }, 500);
                              }}
                            />
                            <span className="icon is-small is-left">
                              <i className="fa fa-phone" />
                            </span>
                          </div>
                          <div className="control">
                            <span
                              className={`button is-static-${
                                phoneVerified ? "success" : "danger"
                              }`}
                            >
                              {phoneVerified ? "Verified" : "Not verified"}
                            </span>
                          </div>
                        </div>
                        <p
                          id="phone-help"
                          className="help"
                          style={{ display: "none", marginTop: "-0.5rem" }}
                        >
                          <i>format: +18001234567</i>
                        </p>

                        <div className="field is-grouped is-grouped-right">
                          <button
                            id="save-phone-btn"
                            className="button is-success"
                            disabled={phoneBtnDisabled}
                          >
                            Verify
                          </button>
                        </div>
                        {/* <p className="help is-danger">Number is not verified</p> */}
                      </div>
                      <div id="recaptcha-container" />
                    </form>
                  </div>
                  <div className="column is-4">
                    <form
                      id="password-form"
                      action=""
                      className=""
                      onSubmit={handlePasswordSubmission}
                    >
                      <label htmlFor="" className="label">
                        New password
                      </label>
                      <div className="field">
                        <div className="field has-addons ">
                          <div className="control is-expanded">
                            <input
                              id="new-password-field"
                              type="password"
                              minLength="6"
                              className="input"
                              name="password"
                              autoComplete="new-password"
                              required
                              onChange={e => {
                                setPasswordBtnDisabled(!e.target.value.length);
                              }}
                            />
                          </div>
                          {/* <div className="control">
                    <span className="button is-static-success">Verified</span>
                  </div> */}
                        </div>
                        <div className="field is-grouped is-grouped-right">
                          <button
                            id="save-password-btn"
                            className="button is-success"
                            disabled={passwordBtnDisabled}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal idPrefix="pass-prompt" isOpen={modalOpen} cb={modalCb}>
        <label htmlFor="">
          For security reasons, provide your current password.
        </label>
        <label htmlFor="" className="label">
          Current password
        </label>
        <div className="field">
          <div className="field has-addons ">
            <div className="control is-expanded">
              <input
                id="current-password-field"
                type="password"
                minLength="6"
                className="input"
                name="password"
                autoComplete="current-password"
                required
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const ProfilePage = ({ location }) => {
  return <PlainPage Content={Content(location)} isPrivate />;
};

export default ProfilePage;
